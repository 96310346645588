<template>
  <div class="vld-parent">
    <div class="bar-content-buttons">
      <button type="button" :class="{'moby-bg moby-btn moby-tab tab-marketing':true, 'active':selectedNavigation(0)}" @click="navigation=0">Medios y Contactos</button>
      <button type="button" :class="{'moby-bg moby-btn moby-tab tab-marketing':true, 'active':selectedNavigation(1)}" @click="navigation=1">Origen (utm_source) y Campañas (utm_campaign)</button>
    
    </div>
    <div id='div-general'>
      <!-- Generales (Medios de información y tipos de contacto) -->
      <!-- <template1 v-if="selectedNavigation(0)" :url="url" :tokenUser="tokenUser" />  -->

      <!-- Utms -->
      <!-- <template2 v-else-if="selectedNavigation(1)" :url="url" :tokenUser="tokenUser" />  -->

      <template-1 v-show="selectedNavigation(0)" :url="url" :tokenUser="tokenUser" :country="country"/>
      <template-2 v-show="selectedNavigation(1)" :url="url" :tokenUser="tokenUser"/>

    </div>
  </div>
</template>


<script>
import Template1 from "./marketing/MediosContactos.vue";
import Template2 from "./marketing/OrigenesCampanhas.vue";
import "vue-loading-overlay/dist/vue-loading.css";


export default {
  name: "MobyDashboard",
  components: {
    Template1, 
    Template2
  },
  data() {
    return {
      isLoading: false,
      navigation: 0,
    };
  },
  props: {
    url: String,
    tokenUser: {String},
    country: {
      type: String
    } 
  },
  methods: {
    onCancel() {
      console.log("User cancelled the loader.");
    },
    selectedNavigation(navigation) {
      return this.navigation == navigation;
    }
  },  
};

</script>

<style scoped>
</style>
