<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <!-- Botón para salir -->
        <button class="modal-default-button" @click="close">✕</button>

        <h3>Confirmación de Envío</h3>
        <p>¿Estás seguro de que deseas enviar este mensaje masivo?</p>
        <p>Escribe "ENVIAR" para confirmar:</p>
        <input
          v-model="confirmationText"
          type="text"
          placeholder="ENVIAR"
          class="confirmation-input"
        />
        <div class="modal-footer">
          <button class="btn btn-success" @click="confirm">Confirmar</button>
          <button class="btn btn-danger" @click="close">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalBulkMessage', // Este es el modal específico que modificamos
  data() {
    return {
      confirmationText: '', // El texto ingresado por el usuario
    }
  },
  methods: {
    confirm() {
      if (this.confirmationText.toUpperCase() === 'ENVIAR') {
        this.$emit('confirmEmit') // Emitir el evento confirm
        this.$emit('close') // Emitir el evento close
      } else {
        alert('Debes escribir "ENVIAR" para confirmar')
      }
    },
    close() {
      this.$emit('close') // Emitir el evento close
    },
  },
}
</script>

<style scoped>
.modal-container {
  width: 400px; /* Aumenta un poco el ancho */
  padding: 35px; /* Espacio interno */
  background-color: #f9f9f9; /* Fondo limpio y profesional */
  border-radius: 12px; /* Bordes suaves */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Sombra sutil */
  font-family: 'Arial', sans-serif; /* Fuente profesional */
}

.modal-header {
  text-align: center; /* Centra el encabezado */
  font-size: 20px; /* Tamaño destacado */
  font-weight: bold; /* Resalta el título */
  color: #333333; /* Color oscuro para contraste */
  margin-bottom: 20px; /* Separación del contenido */
}

.modal-body {
  font-size: 14px; /* Tamaño cómodo para leer */
  line-height: 1.6; /* Mejora la legibilidad */
  color: #555555; /* Texto en gris medio */
}

.modal-body input[type='text'] {
  width: 100%; /* Ocupa todo el ancho */
  padding: 10px; /* Espacio interno */
  border: 2px solid #cccccc; /* Borde suave */
  border-radius: 6px; /* Bordes redondeados */
  font-size: 14px; /* Tamaño adecuado */
  margin-top: 10px; /* Espacio superior */
  margin-bottom: 20px; /* Espacio inferior */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra interna */
}

/* Estilo para el cuadro de texto con el placeholder */
.confirmation-input {
  width: 100%; /* Ocupa todo el ancho */
  padding: 5px; /* Espacio interno */
  border: 2px solid #cccccc; /* Borde suave */
  border-radius: 6px; /* Bordes redondeados */
  font-size: 14px; /* Tamaño adecuado */
  margin-top: 10px; /* Espacio superior */
  margin-bottom: 20px; /* Espacio inferior */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra interna */
}

.confirmation-input::placeholder {
  color: rgba(0, 0, 0, 0.4); /* Desvanecida */
  font-style: italic; /* Estilo cursiva para diferenciar */
}

/* Estilo de los botones en el pie de la ventana */
.modal-footer {
  display: flex; /* Alinea botones horizontalmente */
  justify-content: space-between; /* Espaciado entre botones */
  margin-top: 20px; /* Espacio superior */
}

.modal-footer button {
  padding: 10px 20px; /* Botones amplios */
  font-size: 14px; /* Tamaño de texto adecuado */
  border: none; /* Sin bordes */
  border-radius: 6px; /* Bordes redondeados */
  cursor: pointer; /* Cambia el cursor al pasar */
  transition: background-color 0.3s ease; /* Efecto suave */
}

.modal-footer button.btn-success {
  background-color: #4caf50; /* Verde corporativo */
  color: white; /* Texto claro */
}

.modal-footer button.btn-success:hover {
  background-color: #45a049; /* Color más oscuro al pasar */
}

.modal-footer button.btn-danger {
  background-color: #f44336; /* Rojo profesional */
  color: white; /* Texto claro */
}

.modal-footer button.btn-danger:hover {
  background-color: #d32f2f; /* Color más oscuro al pasar */
}

/* Fondo del modal */
.modal-mask {
  background-color: rgba(0, 0, 0, 0.6); /* Oscurece el fondo */
}

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que ocupe toda la altura */
}
</style>
